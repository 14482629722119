const Cta = () => {
  return (
    <div class="cta">
      <a href="https://wa.me/message/JJRSLQK3LTDME1">
        <button>I WANT IT, CLICK HERE</button>
      </a>
    </div>
  );
};

export default Cta;
